import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row, ButtonToolbar, Button,
} from 'reactstrap';
import { useHistory } from 'react-router';
import axios from 'axios';
import Table from './components/MemberTable';
import GrupEditForm from './components/GrupEditForm';
import { GET_GRUP_URL } from '../../../../utils/endpoint';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/types';
import CreateMemberGrup from './components/CreateMemberGrup';

const EditGrup = () => {
  const history = useHistory();
  const [member, setMember] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const listDataTable = CreateMemberGrup(member);

  const { location } = history;
  const { state } = location;
  const { data } = state;

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(GET_GRUP_URL + data?.id, options)
      .then((res) => {
        setMember(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="danger" className="mb-30" onClick={history.goBack}>
              Kembali
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <GrupEditForm />
      </Row>
      <Row>
        <Table listDataTable={listDataTable} isLoading={isLoading} />
      </Row>
    </Container>
  );
};

export default EditGrup;
