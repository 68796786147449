/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';

const CreateDataArticle = (dataAPI) => {
  const columns = useMemo(
    () => [
      {
        Header: 'No.',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        Header: 'Group',
        accessor: 'group',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        username: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            username: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            name: dataAPI[i]?.assign_group_detail?.contact_name,
            phone: dataAPI[i]?.assign_group_detail?.phone,
            title: dataAPI[i]?.title,
            message: dataAPI[i]?.message,
            created_at: dataAPI[i]?.created_at,
            status: dataAPI[i]?.is_delivered ? 'Sukses' : 'Gagal',
            group: dataAPI[i]?.assign_group_detail?.group_contact_detail?.name,
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = {
    tableHeaderData: columns,
    tableRowsData: data,
  };
  return partnerListTableData;
};

export default CreateDataArticle;
