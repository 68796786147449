/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { useHistory } from 'react-router';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { ADVERTISEMENT_URL } from '../../../../../utils/endpoint';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';

const AdvertisementForm = () => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const [progress, setProgress] = useState(null);
  const history = useHistory();

  const [image, setImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    const libraryFormData = new FormData();
    libraryFormData.append('name', e.target.name.value);
    libraryFormData.append('start_date', e.target.start.value);
    libraryFormData.append('end_date', e.target.end.value);
    if (image) {
      libraryFormData.append('image', image[0]);
    }

    axios
      .post(ADVERTISEMENT_URL, libraryFormData, options)
      .then(() => {
        history.push('/admin/advertisement');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (

    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Buat Iklan Website</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Judul</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Gambar</span>
                <div className="form__form-group-field">
                  <Field
                    onChange={(e) => setImage(e)}
                    name="image"
                    component={renderDropZoneField}
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tanggal Mulai</span>
              <div className="form__form-group-field">
                <Field
                  name="start"
                  component="input"
                  type="date"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tanggal Selesai</span>
              <div className="form__form-group-field">
                <Field
                  name="end"
                  component="input"
                  type="date"
                />
              </div>
            </div>

            {progress !== null && (
              <div className="form__form-group">
                <div className="progress-wrap progress-wrap--middle block">
                  <Progress animated value={progress} />
                </div>
              </div>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">Save</Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_advertisement', // a unique identifier for this form
})(AdvertisementForm);
