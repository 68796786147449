import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import ReactTableBase from '../../../../../../shared/components/table/ReactTableBase';
import ExportExcellRegister from './ExportExcellRegister';

const CompetitionMemberListTable = ({ listDataTableRegister }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Registrasi</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <ExportExcellRegister />
            </ButtonToolbar>
          </div>

          <ReactTableBase
            columns={listDataTableRegister.tableHeaderData}
            data={listDataTableRegister.tableRowsData}
            tableConfig={tableConfig}
          />

        </CardBody>
      </Card>
    </Col>
  );
};

CompetitionMemberListTable.propTypes = {
  listDataTableRegister: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default CompetitionMemberListTable;
