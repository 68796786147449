/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
 Container, Col, Row, Spinner,
} from 'reactstrap';
import Table from './components/LibraryListTable';
import CreateTableData from './components/CreateDataBlastWa';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/types';
import { HISTORY_BLASTWA_URL } from '../../../../utils/endpoint';

const BlastWa = () => {
  const [blast, setBlast] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const listDataTable = CreateTableData(blast);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(HISTORY_BLASTWA_URL, options)
      .then((res) => {
        setBlast(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Blast WhatsApp</h3>
        </Col>
      </Row>
      <Row>
        <Table listDataTable={listDataTable} isLoading={isLoading} />
      </Row>
    </Container>
  );
};
export default BlastWa;
