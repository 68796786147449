/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import TagIcon from 'mdi-react/TagIcon';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router';
import renderSelectField from '../../../../../shared/components/form/Select';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../../utils/types';
import { ARTICLECATEGORY2_URL, COMPETITION_URL } from '../../../../../utils/endpoint';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import Modal from '../../../../../shared/components/Modal';

const CompetitionEditForm = (stateOri) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const editorRef = useRef(null);
  const [articleCategory, setArticleCategory] = useState([]);
  const [content, setContents] = useState('');
  const [progress, setProgress] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const history = useHistory();

  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;

  const [image, setImage] = useState(null);

  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  const competitionName = {
    photo: 'Foto',
    video: 'Video',
    article: 'Artikel',
  };

  useEffect(() => {
    initialize({ ...data, type_competition: { value: data.type_competition, label: competitionName[data.type_competition] }, subcategory_2_id: { value: data.subcategory_2_id, label: data.subcategory_2_detail?.name } });
    setContents(data.description);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    axios.get(ARTICLECATEGORY2_URL, options)
      .then((res) => {
        const categoryList = [];
        for (let i = 0; i < res.data.length; i += 1) {
          const tempCategory = {};
          tempCategory.value = res.data[i].id;
          tempCategory.label = res.data[i].name;
          categoryList.push(tempCategory);
        }
        setArticleCategory(categoryList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        setProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
    };

    const competitionFormData = new FormData();
    const userData = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL));
    competitionFormData.append('name', e.target.name.value);
    competitionFormData.append('type_competition', e.target.type_competition.value);
    competitionFormData.append('subcategory_2_id', e.target.subcategory_2_id.value);
    if (image) {
      competitionFormData.append('image', image[0]);
    }
    competitionFormData.append('start_date', e.target.start_date.value);
    competitionFormData.append('end_date', e.target.end_date.value);
    competitionFormData.append('description', content);
    competitionFormData.append('with_child', e.target.with_child.checked);

    axios
      .patch(`${COMPETITION_URL}/${data.id}/`, competitionFormData, options)
      .then(() => {
        history.push('/admin/competitions');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (

    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Detail Kompetisi</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tipe Kompetisi</span>
              <div className="form__form-group-field">
                <Field
                  name="type_competition"
                  component={renderSelectField}
                  type="text"
                  options={[
                    { value: 'photo', label: 'Foto' },
                    { value: 'video', label: 'Video' },
                    { value: 'article', label: 'Artikel' },
                  ]}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Sub Kategori 2</span>
              <div className="form__form-group-field">
                <Field
                  name="subcategory_2_id"
                  component={renderSelectField}
                  type="text"
                  options={articleCategory}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__half mr-30 lg-mr-0">
              <div className="form__form-group">
                <span className="form__form-group-label block">Gambar</span>
                <span className="form__form-group-label">Gambar Awal{data.image ? '' : ': Tidak ada Gambar'}</span>
                {data.image
                && <img className="initial-image" src={data.image} alt="" />}
              </div>
            </div>
            {
              isEditable
              && (
              <div className="form__half">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      onChange={(e) => setImage(e)}
                      name="imageUpload"
                      component={renderDropZoneField}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>
              )
            }
            <div className="form__form-group">
              <span className="form__form-group-label">Tanggal Mulai</span>
              <div className="form__form-group-field">
                <Field
                  name="start_date"
                  component="input"
                  type="date"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tanggal Akhir</span>
              <div className="form__form-group-field">
                <Field
                  name="end_date"
                  component="input"
                  type="date"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Deskripsi</span>
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  className="absolute-input-for-warning height-30"
                  value={content}
                  required
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                  apiKey="hyf8znnbn86fszd4e8yki04zw6k2rc1ilfg4xrihopdppidh"
                  initialValue={data.description}
                  disabled={!isEditable}
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="with_child"
                  component={renderCheckBoxField}
                  label="Dengan anak ?"
                  disabled={!isEditable}
                />
              </div>
            </div>

            {progress !== null && (
              <div className="form__form-group">
                <div className="progress-wrap progress-wrap--middle block">
                  <Progress animated value={progress} />
                </div>
              </div>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button color={isEditable ? 'secondary' : 'warning'} type="button" onClick={() => setIsEditable(!isEditable)}>{isEditable ? 'Batal' : 'Ubah'}</Button>
              {
                isEditable
                  ? <Button color="primary" type="submit">Simpan Perubahan</Button>
                  : (
                    <Modal
                      color="danger"
                      title="Hapus Kompetisi"
                      header
                      btn="Hapus"
                      deleteFunc={() => {
                        const options = {
                          headers: {
                            'Content-Type':
                              'application/json',
                            Authorization: `Token ${token}`,
                          },
                        };
                        axios
                          .delete(
                            `${COMPETITION_URL}${data.id}`,
                            options,
                          )
                          .then(() => {
                            history.push('/admin/competitions');
                          })
                          .catch(() => {
                            console.log('error');
                          });
                      }}
                      message="Apakah anda yakin ingin menghapus kompetisi ini ?"
                    />
                  )
              }
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_article', // a unique identifier for this form
})(CompetitionEditForm);
