/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Progress,
  Spinner,
  Alert,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router';
import QRCode from 'react-qr-code';
import socket from '../../../../../socket';

const LoginBlastWa = () => {
  const [nomorhp, setNomorHp] = useState('');
  const [qrString, setQrString] = useState('');
  const [loading, setLoading] = useState(false);
  const [connect, setConnect] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const handleQREvent = (qrstring) => {
      setQrString(qrstring.qrString);
    };

    const handleStatusEvent = (connected) => {
      setConnect(connected.connect);
    };

    const handleAuthenticated = (auth) => {
      setAuthenticated(auth.authenticated);
    };

    socket.on('qr', handleQREvent);
    socket.on('status', handleStatusEvent);
    socket.on('authenticated', handleAuthenticated);

    return () => {
      socket.off('qr', handleQREvent);
      socket.off('status', handleStatusEvent);
      socket.off('authenticated', handleAuthenticated);
    };
  }, []);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    if (nomorhp) {
      socket.emit('login', { nomorhp });
      setNomorHp('');
    }
  };

  useEffect(() => {
    if (qrString) {
      setLoading(false);
    }
  }, [qrString]);

  useEffect(() => {
    if (authenticated) {
      window.location.href = '/admin/blast-wa';
    }
  }, [authenticated]);

  const logout = () => {
    socket.emit('signout');
      <Alert color="success" />;
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Login Nomor baru</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            {qrString ? (
              <div
                style={{
                  height: 'auto',
                  margin: '0 auto',
                  maxWidth: 256,
                  width: '100%',
                }}
              >
                <QRCode
                  size={256}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={qrString}
                  viewBox="0 0 1920 1920"
                />
              </div>
            ) : (
              <Spinner
                color="primary"
                className="icon"
                style={{ display: loading ? 'inline-block' : 'none' }}
              />
            )}
            <div className="form__form-group">
              <span className="form__form-group-label">Nomor HP</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Masukkan Nomor HP"
                  onChange={(e) => setNomorHp(e.target.value)}
                />
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">
                  Login
                </Button>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_login', // a unique identifier for this form
})(LoginBlastWa);
