import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'reactstrap';
import axios from 'axios';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { ASSIGN_GRUP_URL } from '../../../../../utils/endpoint';

const grupDetail = (history, info) => {
  history.push({
    pathname: `/admin/member-detail/${info.id}/`,
    state: { data: info },
  });
};

const CreateMemberGrup = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No.',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Contact Name',
        accessor: 'contact_name',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 10,
      },
    ],
    [],
  );

  const data = [];

  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        name: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            name: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            contact_name: dataAPI[i].contact_name,
            phone: dataAPI[i].phone,
            status: [
            // eslint-disable-next-line no-loop-func
            // eslint-disable-next-line max-len
              (<Button key={i} size="sm" color="success" onClick={() => grupDetail(history, dataAPI[i])}>Detail</Button>),
              (
                <Button
                  key={dataAPI[i].id}
                  size="sm"
                  color="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    const options = {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                      },
                    };
                    axios.delete(`${ASSIGN_GRUP_URL}${dataAPI[i].id}/`, options)
                      .then(() => {
                        window.location.reload();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >Hapus
                </Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateMemberGrup;
