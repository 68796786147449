/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, Col, Progress, Spinner,
} from 'reactstrap';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import axios from 'axios';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/types';
import { COMPETITION_URL } from '../../../../utils/endpoint';

const BookingCancels = () => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(COMPETITION_URL, options)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="dashboard__booking-total-container">
            <h5 className="dashboard__booking-total-title">{user.length > 0 ? user.length : (<Spinner />)}</h5>
            <TrendingUpIcon className="dashboard__trend-icon" />
          </div>
          <h5 className="dashboard__booking-total-description">Total Kompetisi</h5>
          <div className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">100%</p>
            <Progress value={100} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default BookingCancels;
