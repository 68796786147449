/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../../utils/types';
import { ARTICLECATEGORY2_URL, LIBRARY_URL, ALLUSER_URL } from '../../../../../utils/endpoint';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import Modal from '../../../../../shared/components/Modal';

const LibraryEditForm = (stateOri) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const editorRef = useRef(null);
  const [progress, setProgress] = useState(null);
  const history = useHistory();

  const [image, setImage] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;

  useEffect(() => {
    initialize(data);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        setProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
    };

    const libraryFormData = new FormData();
    const userData = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL));
    libraryFormData.append('title', e.target.title.value);
    if (image) {
      libraryFormData.append('image', image[0]);
    }
    if (e.target.file.files[0]) {
      libraryFormData.append('file', e.target.file.files[0]);
    }
    libraryFormData.append('is_publish', e.target.is_publish.checked);

    axios
      .patch(`${LIBRARY_URL}${data.id}/`, libraryFormData, options)
      .then(() => {
        history.push('/admin/library');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (

    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Buat Buku Digital</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Judul</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__half mr-30 lg-mr-0">
              <div className="form__form-group">
                <span className="form__form-group-label block">Gambar</span>
                <span className="form__form-group-label">Gambar Awal{data.image ? '' : ': Tidak ada Gambar'}</span>
                {data.image
                && <img className="initial-image" src={data.image} alt="" />}
              </div>
            </div>
            {
              isEditable
              && (
              <div className="form__half">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      onChange={(e) => setImage(e)}
                      name="imageUpload"
                      component={renderDropZoneField}
                    />
                  </div>
                </div>
              </div>
              )
            }

            <div className="form__form-group">
              <span className="form__form-group-label block">File</span>
              <span className="form__form-group-label">File Awal{data.file ? <a target="_blank" rel="noreferrer" href={data.file}>: link file</a> : ': Tidak ada file'}</span>
              {
                isEditable
                && (
                <div className="form__form-group-field">
                  <Field
                    name="file"
                    component={renderFileInputField}
                  />
                </div>
                )
              }
              {/* {alertFilePDF && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file pdf</span>
                )} */}
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="is_publish"
                  component={renderCheckBoxField}
                  label="Apakah buku digital ini ingin ditampilkan ?"
                  disabled={!isEditable}
                />
              </div>
            </div>

            {progress !== null && (
            <div className="form__form-group">
              <div className="progress-wrap progress-wrap--middle block">
                <Progress animated value={progress} />
              </div>
            </div>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button color={isEditable ? 'secondary' : 'warning'} type="button" onClick={() => setIsEditable(!isEditable)}>{isEditable ? 'Batal' : 'Ubah'}</Button>
              {
                isEditable
                  ? <Button color="primary" type="submit">Simpan Perubahan</Button>
                  : (
                    <Modal
                      color="danger"
                      title="Hapus Buku Digital"
                      header
                      btn="Hapus"
                      deleteFunc={() => {
                        const options = {
                          headers: {
                            'Content-Type':
                              'application/json',
                            Authorization: `Token ${token}`,
                          },
                        };
                        axios
                          .delete(
                            `${LIBRARY_URL}/${data.id}`,
                            options,
                          )
                          .then(() => {
                            history.push('/admin/library');
                          })
                          .catch(() => {
                            console.log('error');
                          });
                      }}
                      message="Apakah anda yakin ingin menghapus buku digital ini ?"
                    />
                  )
              }
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'edit_library', // a unique identifier for this form
})(LibraryEditForm);
