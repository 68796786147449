import React from 'react';
import {
  Col, Container, Row, ButtonToolbar, Button,
} from 'reactstrap';
import { useHistory } from 'react-router';
import MemberEditForm from './components/MemberEditForm';

const EditMember = () => {
  const history = useHistory();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="danger" className="mb-30" onClick={history.goBack}>
              Kembali
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <MemberEditForm />
      </Row>
    </Container>
  );
};

export default EditMember;
