/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';

const CreateDataOrderListTable = (dataAPI, imageFunc) => {
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Image',
        accessor: 'image',
        disableGlobalFilter: true,
      },
      {
        Header: 'Nama',
        accessor: 'name',
      },
      {
        Header: 'Alamat',
        accessor: 'address',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phones',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Pembayaran',
        accessor: 'payment',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        username: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            name: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            image: <div onClick={() => imageFunc(dataAPI[i].image)} aria-hidden="true" onKeyPress className="products-list__img-wrap"><img src={dataAPI[i].image} alt="" /></div>,
            name: dataAPI[i].user_detail.fullname,
            address: dataAPI[i].user_detail.address,
            email: dataAPI[i].user_detail.email,
            phone: `${dataAPI[i].user_detail.phone}`,
            payment: dataAPI[i].is_subscribe ? 'Lunas' : 'Belum Lunas',
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateDataOrderListTable;
