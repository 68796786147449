/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory, useParams } from 'react-router';
import renderSelectField from '../../../../../shared/components/form/Select';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../../utils/types';
import {
  ARTICLECATEGORY2_URL, ALLUSER_URL, ARTICLE_URL, POST_URL, GRUP_URL, ASSIGN_GRUP_URL,
} from '../../../../../utils/endpoint';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import Modal from '../../../../../shared/components/Modal';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';

const MemberEditForm = (stateOri) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const params = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [grup, setGrup] = useState();
  const history = useHistory();

  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;

  console.log(data);

  useEffect(() => {
    initialize({
      ...data,
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    setGrup(data.group_id);
  }, [data, initialize, token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    const grupFormData = new FormData();
    const userData = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL));
    grupFormData.append('contact_name', e.target.contact_name.value);
    grupFormData.append('phone', e.target.phone.value);

    axios
      .patch(`${ASSIGN_GRUP_URL}/${params.id}/`, grupFormData, options)
      .then(() => {
        history.push('/admin/grup-blast');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Detail Grup</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama</span>
              <div className="form__form-group-field">
                <Field
                  name="contact_name"
                  component="input"
                  type="text"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama Grup</span>
              <div className="form__form-group-field">
                <Field
                  name="phone"
                  component="input"
                  type="text"
                  disabled={!isEditable}
                />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button color={isEditable ? 'secondary' : 'warning'} type="button" onClick={() => setIsEditable(!isEditable)}>{isEditable ? 'Batal' : 'Ubah'}</Button>
              {
              isEditable
                ? <Button color="primary" type="submit">Simpan Perubahan</Button>
                : (
                  <Modal
                    color="danger"
                    title="Hapus Artikel"
                    header
                    btn="Hapus"
                    deleteFunc={() => {
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${ASSIGN_GRUP_URL}/${data.id}`,
                          options,
                        )
                        .then(() => {
                          history.push('/admin/grup-blast');
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }}
                    message="Apakah anda yakin ingin menghapus member ini ?"
                  />
                )
            }
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'edit_member', // a unique identifier for this form
})(MemberEditForm);
