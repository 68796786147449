import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../Account/LogIn/index';
// events
import Events from '../Admin/Events/ListingEvents/EventListings';
import NewEvent from '../Admin/Events/NewEvents';
import EditEvent from '../Admin/Events/EditEvents';
import EventParticipants from '../Admin/Events/ListingEvents/Participants';
import Articles from '../Admin/Articles/ListingArticles';
import NewArticle from '../Admin/Articles/NewArticles';
import EditArticle from '../Admin/Articles/EditArticles';
import Competitions from '../Admin/Competition/ListingCompetition/Competition';
import NewCompetition from '../Admin/Competition/NewCompetitions';
import EditCompetition from '../Admin/Competition/EditCompetitions';
import CompetitionMembers from '../Admin/Competition/ListingCompetition/Members';
import Library from '../Admin/Library/ListingLibrary';
import NewLibrary from '../Admin/Library/NewLibrary';
import EditLibrary from '../Admin/Library/EditLibrary';
import Dashboard from '../Admin/Dashboard';
import ImageBlast from '../Admin/Email/ListingImage';
import Broadcsting from '../Admin/Email/Broadcasting';
import ListingMembers from '../Admin/Members/ListingMembers';
import Advertisement from '../Admin/Advertisement/ListingAdvertisement';
import NewAdvertisement from '../Admin/Advertisement/NewAdvertisement';
import EditAdvertisement from '../Admin/Advertisement/EditAdvertisement';
import ListingBlastWa from '../Admin/BlasatWa/ListingBlastWa';
import LoginBlastWa from '../Admin/BlasatWa/LoginBlastWa';
import NewBlast from '../Admin/BlasatWa/NewBlastWa';
import ListingGrup from '../Admin/GrupBlast/ListingGrupBlast';
import NewGrup from '../Admin/GrupBlast/NewGrup';
import EditGrup from '../Admin/GrupBlast/EditGrupBlast';
import EditMember from '../Admin/GrupMembers/EditMemberGrup';
import Courses from '../Admin/Courses/ListingCourses/Courses';
import EditCourses from '../Admin/Courses/EditCourses';

const Pages = () => (
  <Switch>
    <Route path="/admin/dashboard" component={Dashboard} />
    <Route path="/admin/events" component={Events} />
    <Route path="/admin/new-event" component={NewEvent} />
    <Route path="/admin/event/:id" component={EditEvent} />
    <Route path="/admin/participants-event/:id" component={EventParticipants} />
    <Route path="/admin/articles" component={Articles} />
    <Route path="/admin/new-article" component={NewArticle} />
    <Route path="/admin/article-detail/:id" component={EditArticle} />
    <Route path="/admin/competitions" component={Competitions} />
    <Route path="/admin/new-competition" component={NewCompetition} />
    <Route path="/admin/competition/:id" component={EditCompetition} />
    <Route path="/admin/member-competition/:id" component={CompetitionMembers} />
    <Route path="/admin/library" component={Library} />
    <Route path="/admin/new-library" component={NewLibrary} />
    <Route path="/admin/library/:id" component={EditLibrary} />
    <Route path="/admin/image" component={ImageBlast} />
    <Route path="/admin/broadcast" component={Broadcsting} />
    <Route path="/admin/members" component={ListingMembers} />
    <Route path="/admin/advertisement" component={Advertisement} />
    <Route path="/admin/new-advertisement" component={NewAdvertisement} />
    <Route path="/admin/advertisement-detail/:id" component={EditAdvertisement} />
    <Route path="/admin/blast-wa" component={ListingBlastWa} />
    <Route path="/admin/login-blast-wa" component={LoginBlastWa} />
    <Route path="/admin/new-blast-wa" component={NewBlast} />
    <Route path="/admin/grup-blast" component={ListingGrup} />
    <Route path="/admin/new-grup-blast" component={NewGrup} />
    <Route path="/admin/grup-detail/:id" component={EditGrup} />
    <Route path="/admin/member-detail/:id" component={EditMember} />
    <Route path="/admin/courses" component={Courses} />
    <Route path="/admin/courses/:id" component={EditCourses} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/pages" component={Pages} />
      <Route path="/admin" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
