/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col, Modal, ModalBody, Progress, Spinner,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { IMAGEGALLERY_URL } from '../../../../../utils/endpoint';

const CompetitionListTable = ({ listDataTable, isLoading }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  // Modal open state
  const [modal, setModal] = React.useState(false);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  const [progress, setProgress] = useState(null);

  const [image, setImage] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    const articleFormData = new FormData();
    if (image) {
      articleFormData.append('image', image[0]);
    }

    axios
      .post(`${IMAGEGALLERY_URL}`, articleFormData, options)
      .then(() => {
        window.location.reload();
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Daftar File</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button
                color="secondary"
                className="btn btn-secondary products-list__btn-add"
                onClick={toggle}
              >Buat File
              </Button>
              <Link className="btn btn-success products-list__btn-add" to="/admin/broadcast">
                Broadcast
              </Link>
            </ButtonToolbar>

            <Modal
              isOpen={modal}
              toggle={toggle}
            >
              <ModalBody>
                <Col md={12} lg={12}>
                  <Card>
                    <CardBody>
                      <form className="form product-edit" onSubmit={handleSubmit}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Unggah Gambar</span>
                          <div className="form__form-group-field">
                            <Field
                              onChange={(e) => setImage(e)}
                              name="image"
                              component={renderDropZoneField}
                            />
                          </div>
                        </div>
                        {progress !== null && (
                        <div className="form__form-group">
                          <div className="progress-wrap progress-wrap--middle block">
                            <Progress animated value={progress} />
                          </div>
                        </div>
                        )}

                        <ButtonToolbar className="form__button-toolbar">
                          <Button color="primary" type="submit">Save</Button>
                        </ButtonToolbar>
                      </form>
                    </CardBody>
                  </Card>
                </Col>

              </ModalBody>
            </Modal>
          </div>
          {isLoading
            ? <Spinner />
            : (
              <ReactTableBase
                columns={listDataTable.tableHeaderData}
                data={listDataTable.tableRowsData}
                tableConfig={tableConfig}
              />
            )}
        </CardBody>
      </Card>
    </Col>
  );
};

CompetitionListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool,
};

CompetitionListTable.defaultProps = {
  isLoading: false,
};

export default reduxForm({
  form: 'create_file', // a unique identifier for this form
})(CompetitionListTable);
