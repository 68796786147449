/* eslint-disable no-console */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import { useParams } from 'react-router';
import moment from 'moment';
import { Button } from 'reactstrap';
import { EVENT_URL } from '../../../../../../utils/endpoint';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/types';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const ExportParticipants = () => {
  const params = useParams();
  const [userManagement, setuserManagement] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${EVENT_URL}${params.id}`, options)
      .then((res) => {
        setuserManagement(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);
  // console.log(userManagement);
  const data = userManagement !== null && userManagement.event_regitrated_list
    ? userManagement.event_regitrated_list.map((e) => ({
      name: e.user_detail.fullname,
      address: e.user_detail.address,
      email: e.user_detail.email,
      phone: e.user_detail.phone,
      payment: e.is_subscribe ? 'Lunas' : 'Belum Lunas',
      date: moment().format('LLLL'),
      bukti: e.image,
    })) : [];
  //   console.log('WKWK', data, userManagement !== null && userManagement.title);
  return (
    <ExcelFile filename={`Participants-${userManagement.title}`} element={<Button>Download</Button>}>
      <ExcelSheet data={data} name="Event">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Address" value="address" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Phone" value="phone" />
        <ExcelColumn label="Payment" value="payment" />
        <ExcelColumn label="Date" value="date" />
        <ExcelColumn label="Image" value="bukti" />
      </ExcelSheet>
    </ExcelFile>
  );
};
export default ExportParticipants;
