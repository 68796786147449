/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const PhotoFormatter = (value) => (
  <div className="products-list__img-wrap">
    <img src={`${value}`} alt="" />
  </div>
);
const courseDetail = (history, info, url) => {
  history.push({
    pathname: `${url}${info.id}/`,
    state: { data: info },
  });
};

const CreateDataCourses = (dataAPI) => {
  // console.log('DATA USER', dataAPI);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Jadwal',
        accessor: 'thumbnail',
        disableGlobalFilter: true,
      },
      {
        Header: 'Silabus',
        accessor: 'image_2',
        disableGlobalFilter: true,
      },
      {
        Header: 'Judul',
        accessor: 'title',
      },
      {
        Header: 'Tanggal Dibuat',
        accessor: 'created_at',
      },
      {
        Header: 'Status Publikasi',
        accessor: 'is_publish',
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        username: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            username: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            thumbnail: PhotoFormatter(`${dataAPI[i].thumbnail}`),
            image_2: PhotoFormatter(`${dataAPI[i].image_2}`),
            title: dataAPI[i].title,
            created_at: dataAPI[i].created_at,
            is_publish: dataAPI[i].is_publish,
            status: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button key={dataAPI[i].id} size="sm" color="secondary" onClick={() => courseDetail(history, dataAPI[i], '/admin/courses/')}>Detail</Button>),
              (<Button key={i} size="sm" color="success" onClick={() => courseDetail(history, dataAPI[i], '/admin/member-competition/')}>Peserta</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateDataCourses;
