/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
 Container, Col, Row, Spinner,
} from 'reactstrap';
import Table from './components/ArticleListTable';
import CreateTableData from './components/CreateDataArticle';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/types';
import { ARTICLE_URL } from '../../../../utils/endpoint';

const Articles = () => {
  const [article, setArticle] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const listDataTable = CreateTableData(article);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(ARTICLE_URL, options)
      .then((res) => {
        setArticle(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Artikel</h3>
        </Col>
      </Row>
      <Row>
        <Table listDataTable={listDataTable} isLoading={isLoading} />
      </Row>
    </Container>
  );
  };
export default Articles;
