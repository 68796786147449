/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
 Container, Col, Row, Spinner,
} from 'reactstrap';
import CoursesListTable from './components/CoursesListTable';
import CreateDataCourses from './components/CreateDataCourses';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { COURSES_URL } from '../../../../../utils/endpoint';

const Courses = () => {
  const [competition, setCompetition] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const listDataTable = CreateDataCourses(competition);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(COURSES_URL, options)
      .then((res) => {
        setCompetition(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Courses</h3>
        </Col>
      </Row>
      <Row>
        <CoursesListTable listDataTable={listDataTable} isLoading={isLoading} />
      </Row>
    </Container>
  );
  };
export default Courses;
