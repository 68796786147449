import React from 'react';
import {
  Col, Container, Row, ButtonToolbar, Button,
} from 'reactstrap';
import { useHistory } from 'react-router';
import CoursesEditForm from './components/CoursesEditForm';

const EditCourses = () => {
  const history = useHistory();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="danger" className="mb-30" onClick={history.goBack}>
              Kembali
            </Button>
          </ButtonToolbar>
          {/* <h3 className="page-title">Article Add</h3> */}
          {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
            information
          </h3> */}
        </Col>
      </Row>
      <Row>
        <CoursesEditForm />
      </Row>
    </Container>
  );
};

export default EditCourses;
