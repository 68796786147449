/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { useHistory } from 'react-router';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { ADVERTISEMENT_URL } from '../../../../../utils/endpoint';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import Modal from '../../../../../shared/components/Modal';

const LibraryEditForm = (stateOri) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const [progress, setProgress] = useState(null);
  const history = useHistory();

  const [image, setImage] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;

  useEffect(() => {
    initialize(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        setProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
    };

    const libraryFormData = new FormData();
    libraryFormData.append('name', e.target.name.value);
    libraryFormData.append('start_date', e.target.start_date.value);
    libraryFormData.append('end_date', e.target.end_date.value);
    if (image) {
      libraryFormData.append('image', image[0]);
    }

    axios
      .patch(`${ADVERTISEMENT_URL}${data.id}/`, libraryFormData, options)
      .then(() => {
        history.push('/admin/advertisement');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (

    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Buat Buku Digital</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Name</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tanggal Mulai</span>
              <div className="form__form-group-field">
                <Field
                  name="start_date"
                  component="input"
                  type="date"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tanggal Selesai</span>
              <div className="form__form-group-field">
                <Field
                  name="end_date"
                  component="input"
                  type="date"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__half mr-30 lg-mr-0">
              <div className="form__form-group">
                <span className="form__form-group-label block">Gambar</span>
                <span className="form__form-group-label">Gambar Awal{data.image ? '' : ': Tidak ada Gambar'}</span>
                {data.image
                && <img className="initial-image" src={data.image} alt="" />}
              </div>
            </div>
            {
              isEditable
              && (
              <div className="form__half">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      onChange={(e) => setImage(e)}
                      name="imageUpload"
                      component={renderDropZoneField}
                    />
                  </div>
                </div>
              </div>
              )
            }

            {progress !== null && (
            <div className="form__form-group">
              <div className="progress-wrap progress-wrap--middle block">
                <Progress animated value={progress} />
              </div>
            </div>
            )}
            <div className="form__form-group">
              <ButtonToolbar className="form__button-toolbar">
                <Button color={isEditable ? 'secondary' : 'warning'} type="button" onClick={() => setIsEditable(!isEditable)}>{isEditable ? 'Batal' : 'Ubah'}</Button>
                {
                isEditable
                  ? <Button color="primary" type="submit">Simpan Perubahan</Button>
                  : (
                    <Modal
                      color="danger"
                      title="Hapus Iklan"
                      header
                      btn="Hapus"
                      deleteFunc={() => {
                        const options = {
                          headers: {
                            'Content-Type':
                              'application/json',
                            Authorization: `Token ${token}`,
                          },
                        };
                        axios
                          .delete(
                            `${ADVERTISEMENT_URL}/${data.id}`,
                            options,
                          )
                          .then(() => {
                            history.push('/admin/library');
                          })
                          .catch(() => {
                            console.log('error');
                          });
                      }}
                      message="Apakah anda yakin ingin menghapus iklan digital ini ?"
                    />
                  )
              }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'edit_library', // a unique identifier for this form
})(LibraryEditForm);
