import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, ButtonToolbar, Card, CardBody, Col, Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import socket from '../../../../../socket';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';

const ArticleListTable = ({ listDataTable, isLoading }) => {
  const [loggedOut, setLoggedOut] = useState(false);
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const [nomorLogin, setnomorlogin] = useState('');
  useEffect(() => {
    const handleNomorLogin = (nomor) => {
      setnomorlogin(nomor.nomorlogin);
    };

    const handleLogout = (logout) => {
      setLoggedOut(logout.logout);
    };

    socket.on('nomorlogin', handleNomorLogin);
    socket.on('logoutdone', handleLogout);

    return () => {
      socket.off('nomorlogin', handleNomorLogin);
      socket.off('logoutdone', handleLogout);
    };
  }, [nomorLogin, loggedOut]);

  useEffect(() => {
    if (loggedOut) {
      window.location.reload();
    }
  }, [loggedOut]);

  const logout = () => {
    socket.emit('signout');
    Alert('Berhasil Logout');
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Daftar Blast WhatsApp</h5>
            {nomorLogin ? (
              <ButtonToolbar
                className="products-list__btn-toolbar-center"
                onClick={logout}
              >
                <div className="btn btn-primary products-list__btn-add">
                  Logout: {nomorLogin}
                </div>
              </ButtonToolbar>
            ) : (
              <ButtonToolbar className="products-list__btn-toolbar-center">
                <Link
                  className="btn btn-primary products-list__btn-add"
                  to="/admin/login-blast-wa"
                >
                  Login
                </Link>
              </ButtonToolbar>
            )}
            {nomorLogin && (
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <Link
                  className="btn btn-primary products-list__btn-add"
                  to="/admin/new-blast-wa"
                >
                  Buat Pesan Baru
                </Link>
              </ButtonToolbar>
            )}
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

ArticleListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool,
};

ArticleListTable.defaultProps = {
  isLoading: false,
};

export default ArticleListTable;
