/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router';
import XLSX from 'xlsx';
import renderSelectField from '../../../../../shared/components/form/Select';
import {
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_USERDETAIL,
} from '../../../../../utils/types';
import contactNumbers from '../../../../../data/phonenumber';
import socket from '../../../../../socket';
import {
  ASSIGN_GRUP_URL,
  GET_GRUP_URL,
  GRUP_URL,
  HISTORY_BLASTWA_URL,
} from '../../../../../utils/endpoint';

const BlastForm = () => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const [title, setTitle] = useState('');
  const [phonenumbers, setPhoneNumbers] = useState([]);
  const [message, setMessage] = useState('');
  const [media, setMedia] = useState('');
  const [percent, setPercent] = useState('');
  const [showPercent, setShowPercent] = useState(false);
  const [nama, setNama] = useState('');
  const [allGrup, setAllGrup] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    socket.on('sendsuccess', (name) => {
      setNama(name);
    });
    socket.on('percen', (percentage) => {
      setPercent(percentage);
    });
    if (percent === 100) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      setShowPercent(false);
    }
  }, [percent]);

  useEffect(() => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(GRUP_URL, options).then((res) => {
      const grupList = [];
      res.data.forEach((element) => {
        grupList.push({
          value: element.id,
          label: element.name,
        });
        setAllGrup(grupList);
      });
    });
  }, [token]);

  const getGrupData = (value) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${GET_GRUP_URL}${value.value}`, options).then((res) => {
      setPhoneNumbers(res.data);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowPercent(true);
    if (message && phonenumbers) {
      socket.emit('broadcast', {
        phonenumbers, message, media, title, token,
      });
      setPhoneNumbers([]);
      setMessage('');
      setMedia('');
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Broadcast Baru</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Title</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Masukan judul blast"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Grup</span>
              <div className="form__form-group-field">
                <Field
                  name="grup"
                  component={renderSelectField}
                  type="text"
                  options={allGrup}
                  onChange={(value) => getGrupData(value)}
                  placeholder="Pilih grup untuk broadcast"
                />
              </div>
            </div>

            {/* <div className="form__form-group">
              <span className="form__form-group-label">File excel</span>
              <div className="form__form-group-field">
                <input type="file" accept=".xlsx" onChange={handleFileUpload} />
              </div>
            </div> */}

            <div className="form__form-group">
              <span className="form__form-group-label">Pesan</span>
              <div className="form__form-group-field">
                <Field
                  name="Message"
                  component="textarea"
                  type="text"
                  placeholder="Masukan Pesan"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Media Link</span>
              <div className="form__form-group-field">
                <Field
                  name="Media"
                  component="input"
                  type="text"
                  placeholder="Masukan Link Image"
                  onChange={(e) => setMedia(e.target.value)}
                />
              </div>
            </div>

            {/* {progress && (
              <div className="form__form-group">
                <div className="progress-wrap progress-wrap--middle block">
                  <Progress animated value={progress} />
                </div>
              </div>
            )} */}

            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">
                Kirim
              </Button>
            </ButtonToolbar>
          </form>
          {showPercent && (
            <div className="form__form-group">
              <p>Sending to: {nama}</p>
              <div className="progress-wrap progress-wrap--middle block">
                <Progress animated value={percent} />
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_blast', // a unique identifier for this form
})(BlastForm);
