/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const PhotoFormatter = (value) => (
  <div className="products-list__img-wrap">
    <img src={`${value}`} alt="" />
  </div>
);
const competitionDetail = (history, info, url) => {
  history.push({
    pathname: `${url}${info.id}/`,
    state: { data: info },
  });
};

const CreateDataCompetition = (dataAPI) => {
  // console.log('DATA USER', dataAPI);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Gambar',
        accessor: 'image',
        disableGlobalFilter: true,
      },
      {
        Header: 'Nama',
        accessor: 'name',
      },
      {
        Header: 'Tanggal mulai',
        accessor: 'start_date',
      },
      {
        Header: 'Tanggal Berakhir',
        accessor: 'end_date',
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        username: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            username: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            image: PhotoFormatter(`${dataAPI[i].image}`),
            name: dataAPI[i].name,
            start_date: dataAPI[i].start_date,
            end_date: dataAPI[i].end_date,
            status: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button key={dataAPI[i].id} size="sm" color="secondary" onClick={() => competitionDetail(history, dataAPI[i], '/admin/competition/')}>Detail</Button>),
              (<Button key={i} size="sm" color="success" onClick={() => competitionDetail(history, dataAPI[i], '/admin/member-competition/')}>Peserta</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateDataCompetition;
