/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';

const CreateDataOrderListTable = (dataAPI, imageFunc) => {
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Image',
        accessor: 'image',
        disableGlobalFilter: true,
      },
      {
        Header: 'Nama',
        accessor: 'name',
      },
      {
        Header: 'Alamat',
        accessor: 'address',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Login',
        accessor: 'login',
      },
      {
        Header: 'Terdaftar',
        accessor: 'register',
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        username: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            name: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            image: <div onClick={() => imageFunc(dataAPI[i].image)} aria-hidden="true" onKeyPress className="products-list__img-wrap"><img src={dataAPI[i].image_url} alt="" /></div>,
            name: dataAPI[i].fullname ?? '',
            address: dataAPI[i].address ?? '',
            email: dataAPI[i].email ?? '',
            phone: dataAPI[i].phone ?? '',
            login: dataAPI[i].last_login ?? '',
            register: dataAPI[i].created_at ?? '',
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateDataOrderListTable;
