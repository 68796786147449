/* eslint-disable no-alert */
/* eslint-disable no-loop-func */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { IMAGEGALLERY_URL } from '../../../../../utils/endpoint';

const PhotoFormatter = (value) => (
  <div className="products-list__img-wrap">
    <img src={`${value}`} alt="" />
  </div>
);

const CreateDataCompetition = (dataAPI) => {
  // console.log('DATA USER', dataAPI);
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Gambar',
        accessor: 'image',
        disableGlobalFilter: true,
      },
      {
        Header: 'Nama',
        accessor: 'name',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Tanggal',
        accessor: 'date',
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        username: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            username: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            image: PhotoFormatter(`${dataAPI[i].image}`),
            name: <a href={dataAPI[i].image} target="blank">{`${dataAPI[i].image.slice(53)}`}</a>,
            date: dataAPI[i].created_at,
            status: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (
                <Button
                  key={dataAPI[i].id}
                  size="sm"
                  color="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    const options = {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                      },
                    };
                    axios.delete(`${IMAGEGALLERY_URL}${dataAPI[i].id}/`, options)
                      .then(() => {
                        window.location.reload();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >Hapus
                </Button>),
              <CopyToClipboard
                key={i + 1}
                text={dataAPI[i].image}
              >
                <Button
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    alert('data berhasil dicopy');
                  }}
                >Copy
                </Button>
              </CopyToClipboard>,
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateDataCompetition;
