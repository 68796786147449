import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Alert } from 'reactstrap';
import LogInForm from './components/LogInForm';
import {
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_USERDETAIL,
} from '../../../utils/types';
import {
  AUTH_URL_LOGIN,
  AUTH_URL_USERME,
} from '../../../utils/endpoint';

const LogIn = () => {
  const [isVisibleAlert, setVisibleAlert] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const history = useHistory();
  const errorHandling = (msg) => {
    setMessage(msg);
    setLoading(false);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 5000);
  };
  const onSubmit = (e) => {
    const data = new FormData();
    data.append('email', e.email);
    data.append('password', e.password);
    setLoading(true);
    axios.post(AUTH_URL_LOGIN, data)
      .then((res) => {
        // authentication
        const token = res.data.auth_token;

        localStorage.setItem(LOCALSTORAGE_TOKEN, token);
        // authorization
        axios.get(AUTH_URL_USERME, { headers: { Authorization: `Token ${token}` } }).then((userData) => {
          const userdetail = userData.data;
          for (let i = 0; i < userdetail.roles.length; i += 1) {
            if (userdetail.roles[i].name === 'admin' || userdetail.roles[i].name === 'editor') {
              localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
              history.push('/admin/dashboard');
            } else {
              errorHandling('Anda tidak memiliki hak untuk mengakses Educourse Report Management');
            }
          }
          setLoading(false);
        }).catch((error) => {
          errorHandling(error.response.data);
        });
      }).catch((error) => {
        errorHandling(error.response.data.non_field_errors[0]);
      });
  };
  if (isLoading) {
    return (
      <div className="load">
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>
    );
  }
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Welcome to
              <span className="account__logo"> ibupembelajar.
                <span className="account__logo-accent">id</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">Start your business easily</h4>
          </div>
          <div className="login-form" style={{ marginBottom: '20px' }}>
            {isVisibleAlert ? (
              <Alert color="danger">
                {message}
              </Alert>
            ) : ''}
          </div>
          <LogInForm onSubmit={(e) => onSubmit(e)} />
        </div>
      </div>
    </div>
  );
};
export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
