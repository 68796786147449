/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import { LOCALSTORAGE_USERDETAIL } from '../../../utils/types';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick, changeToDark, changeToLight }) => {
  const [showList, setShowList] = React.useState(false);
  const handleShowList = () => {
    setShowList(!showList);
  };
  const handleHideSidebar = () => {
    onClick();
  };
  // const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL));
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          title="Dashboard"
          icon="home"
          route="/admin/dashboard"
          onClick={handleHideSidebar}
        />
        <SidebarLink
          title="Anggota"
          icon="users"
          route="/admin/members"
          onClick={handleHideSidebar}
        />
        <SidebarLink
          title="Kegiatan & Webinar"
          icon="calendar-full"
          route="/admin/events"
          onClick={handleHideSidebar}
        />
        <SidebarLink
          title="Artikel"
          icon="book"
          route="/admin/articles"
          onClick={handleHideSidebar}
        />
        <SidebarLink
          title="Kompetisi"
          icon="flag"
          route="/admin/competitions"
          onClick={handleHideSidebar}
        />
        <SidebarLink
          title="Perpustakaan"
          icon="bookmark"
          route="/admin/library"
          onClick={handleHideSidebar}
        />
        <SidebarLink
          title="Courses"
          icon="bookmark"
          route="/admin/courses"
          onClick={handleHideSidebar}
        />
        <SidebarLink
          title="Email"
          icon="layers"
          route="/admin/image"
          onClick={handleHideSidebar}
        />
        <SidebarLink
          title="Advertisement"
          icon="screen"
          route="/admin/advertisement"
          onClick={handleHideSidebar}
        />
        <SidebarCategory title="Broadcast" icon="phone">
          <SidebarLink
            title="Group"
            route="/admin/grup-blast"
            onClick={handleShowList}
          />
          <SidebarLink
            title="Blast WA"
            route="/admin/blast-wa"
            onClick={handleShowList}
          />
        </SidebarCategory>
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
