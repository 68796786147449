/* eslint-disable func-names */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router';
import renderSelectField from '../../../../../shared/components/form/Select';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../../utils/types';
import { EVENT_URL } from '../../../../../utils/endpoint';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';

const EventForm = () => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const editorRef = useRef(null);
  const [content, setContents] = useState('');
  const [progress, setProgress] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const categoryRef = useRef(null);
  const typeRef = useRef(null);
  const history = useHistory();

  const [image, setImage] = useState(null);

  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  useEffect(() => {
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    const eventFormData = new FormData();
    eventFormData.append('title', e.target.title.value);
    eventFormData.append('category', e.target.category.value);
    eventFormData.append('type', e.target.type.value);
    if (image) {
      eventFormData.append('image', image[0]);
    }
    eventFormData.append('date', e.target.date.value);
    eventFormData.append('time', e.target.time.value);
    eventFormData.append('price', e.target.price.value);
    eventFormData.append('message', e.target.message.value);
    eventFormData.append('description', content);
    eventFormData.append('url_subscribed', e.target.url_subscribed.value);
    if (e.target.file_material.files[0]) {
      eventFormData.append('file_material', e.target.file_material.files[0]);
    }
    eventFormData.append('speaker_name', e.target.speaker_name.value);
    eventFormData.append('speaker_position', e.target.speaker_position.value);
    eventFormData.append('is_upload_image', e.target.is_upload_image.checked);

    axios
      .post(EVENT_URL, eventFormData, options)
      .then(() => {
        history.push('/admin/events');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (

    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Buat Kegiatan/Webinar</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Judul</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Masukan judul kegiatan atau webinar"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Kategori</span>
              <div className="form__form-group-field relative">
                <Field
                  name="category"
                  component={renderSelectField}
                  type="text"
                  options={[{ value: 'ipi', label: 'IPI' }, { value: 'kolaborasi', label: 'KOLABORASI' }]}
                  onChange={(e) => setCategoryValue(e.value)}
                  selectFieldRef={categoryRef}
                  placeholder="Masukan kategoro kegiatan atau webinar"
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  className="absolute-input-for-warning"
                  value={categoryValue}
                  onFocus={() => categoryRef.current.focus()}
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tipe</span>
              <div className="form__form-group-field relative">
                <Field
                  name="type"
                  component={renderSelectField}
                  type="text"
                  options={[{ value: 'kulwap', label: 'KULWAP' }, { value: 'webinar', label: 'WEBINAR' }, { value: 'kursus/workshop', label: 'KURSUS/WORKSHOP' }, { value: 'instagram live / talk show', label: 'Instagram Live / Talk Show' }]}
                  onChange={(e) => setTypeValue(e.value)}
                  selectFieldRef={typeRef}
                  placeholder="Masukan tipe kegiatan atau webinar"
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  className="absolute-input-for-warning"
                  value={typeValue}
                  onFocus={() => typeRef.current.focus()}
                  required
                />
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Gambar</span>
                <div className="form__form-group-field">
                  <Field
                    onChange={(e) => setImage(e)}
                    name="image"
                    component={renderDropZoneField}
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="is_upload_image"
                  component={renderCheckBoxField}
                  label="Apakah anda mengunggah gambar ?"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tanggal</span>
              <div className="form__form-group-field">
                <Field
                  name="date"
                  component="input"
                  type="date"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Waktu</span>
              <div className="form__form-group-field">
                <Field
                  name="time"
                  component="input"
                  type="time"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Harga</span>
              <div className="form__form-group-field">
                <Field
                  name="price"
                  component="input"
                  type="number"
                  placeholder="Masukan harga kegiatan atau webinar"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Pesan</span>
              <div className="form__form-group-field">
                <Field
                  name="message"
                  component="input"
                  type="text"
                  placeholder="Masukan pesan kegiatan atau webinar"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Deskripsi</span>
              <div className="form__form-group-field w-100-role-application relative">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  className="absolute-input-for-warning height-30"
                  value={content}
                  required
                />
                <Editor
                  required
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                  apiKey="hyf8znnbn86fszd4e8yki04zw6k2rc1ilfg4xrihopdppidh"
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Url Berlangganan</span>
              <div className="form__form-group-field">
                <Field
                  name="url_subscribed"
                  component="input"
                  type="url"
                  placeholder="Masukan url kegiatan atau webinar"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">File Materi</span>
              <div className="form__form-group-field">
                <Field
                  name="file_material"
                  component={renderFileInputField}
                />
              </div>
              {/* {alertFilePDF && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file pdf</span>
                )} */}
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama Pembicara</span>
              <div className="form__form-group-field">
                <Field
                  name="speaker_name"
                  component="input"
                  type="text"
                  placeholder="Masukan pembicara kegiatan atau webinar"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Posisi Pembicara</span>
              <div className="form__form-group-field">
                <Field
                  name="speaker_position"
                  component="input"
                  type="text"
                  placeholder="Masukan posisi pembicara kegiatan atau webinar"
                />
              </div>
            </div>

            {progress !== null && (
              <div className="form__form-group">
                <div className="progress-wrap progress-wrap--middle block">
                  <Progress animated value={progress} />
                </div>
              </div>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">Save</Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_event', // a unique identifier for this form
})(EventForm);
