import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import XLSX from 'xlsx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { ASSIGN_GRUP_URL } from '../../../../../utils/endpoint';

const MemberListTable = ({ listDataTable, isLoading }) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const columns = [
    {
      Header: 'No.',
      accessor: 'number',
    },
    {
      Header: 'Contact Name',
      accessor: 'contact_name',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
  ];

  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const tableConfig2 = {
    isResizable: false,
    withPagination: true,
  };

  const rows = excelData.map((item, index) => ({
    id: item?.id,
    number: `${index + 1}`,
    contact_name: item?.contact_name,
    // eslint-disable-next-line max-len
    phone: [<span style={{ color: `${item.phone}`.startsWith('62') ? 'inherit' : 'red' }}>{item?.phone}</span>],
  }));

  const postMembers = () => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    if (Array.isArray(excelData)) {
      setUploadLoading(true);
      const postPromises = excelData?.map((item) => {
        const itemPost = { ...item, group_id: data.id };
        return axios.post(ASSIGN_GRUP_URL, itemPost, options);
      });

      Promise.all(postPromises)
        .then(() => {
          setUploadLoading(false);
          console.log('All data posted successfully');
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          setUploadLoading(false);
          console.error(err);
        });
    } else {
      setUploadLoading(false);
      console.error('value is not an array');
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setExcelData([]);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setLoading(true);

    const reader = new FileReader();
    reader.onload = (event) => {
      const excel = new Uint8Array(event.target.result);
      const workbook = XLSX.read(excel, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const parsedData = XLSX.utils.sheet_to_json(sheet);

      const isInvalidData = parsedData?.some((item) => {
        const phoneString = item?.phone?.toString();
        return !phoneString.startsWith('62');
      });

      if (isInvalidData) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }

      setExcelData(parsedData);
      setShowModal(true);
      setLoading(false);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">DAFTAR MEMBER GROUP</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <label
                htmlFor="file"
                className="btn btn-primary products-list__btn-add"
              >
                {loading ? <Spinner /> : 'Upload Excel'}
                <input
                  id="file"
                  type="file"
                  accept=".xlsx, .xls"
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />
              </label>
            </ButtonToolbar>
          </div>
          <Modal isOpen={showModal} style={{ maxWidth: '700px', width: '100%' }}>
            <ModalHeader toggle={closeModal}>Preview Excel</ModalHeader>
            <ModalBody>
              {excelData.length > 0 ? (
                <ReactTableBase
                  columns={columns}
                  data={rows}
                  tableConfig={tableConfig2}
                />
              ) : (
                'No excel data'
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={closeModal}>
                Batal
              </Button>
              <Button color="success" onClick={postMembers} disabled={isDisabled}>
                {uploadLoading ? <Spinner /> : 'Upload'}
              </Button>
            </ModalFooter>
          </Modal>
          {isLoading ? (
            <Spinner />
          ) : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

MemberListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool,
};

MemberListTable.defaultProps = {
  isLoading: false,
};

export default MemberListTable;
