/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';

// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );

const CreateDataCompetitionMember = (dataAPI) => {
  // console.log('DATA USER', dataAPI);
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
      },
      {
        Header: 'Tanggal',
        accessor: 'date',
      },
      {
        Header: 'Nama',
        accessor: 'name',
      },
      {
        Header: 'Instagram',
        accessor: 'ig',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        username: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            name: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            date: dataAPI[i].created_at,
            name: dataAPI[i].user_detail.fullname,
            ig: dataAPI[i].instagram_account,
            phone: dataAPI[i].phone,
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateDataCompetitionMember;
