/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory, useParams } from 'react-router';
import renderSelectField from '../../../../../shared/components/form/Select';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../../utils/types';
import {
  ARTICLECATEGORY2_URL, ALLUSER_URL, ARTICLE_URL, POST_URL,
} from '../../../../../utils/endpoint';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import Modal from '../../../../../shared/components/Modal';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';

const ArticleEditForm = (stateOri) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const editorRef = useRef(null);
  const params = useParams();
  const [articleCategory, setArticleCategory] = useState([]);
  const [content, setContents] = useState('');
  const [progress, setProgress] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const history = useHistory();
  const [userDownloadProgress, setUserDownloadProgress] = useState(0);
  const [allUser, setAllUser] = useState([]);

  const [image, setImage] = useState(null);

  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;
  console.log('res', data);

  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  useEffect(() => {
    initialize({
      ...data, editor_id: data.editor_detail?.email, subcategory_2_id: { value: data.subcategory2_detail?.id, label: data.subcategory2_detail?.name }, author_id: { value: data.author_id, label: data.author_detail.email },
    });
    setContents(data.content);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(ARTICLECATEGORY2_URL, options)
      .then((res) => {
        const categoryList = [];
        for (let i = 0; i < res.data.length; i += 1) {
          const tempCategory = {};
          tempCategory.value = res.data[i].id;
          tempCategory.label = res.data[i].name;
          categoryList.push(tempCategory);
        }
        setArticleCategory(categoryList);
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get(ALLUSER_URL, {
      ...options,
      onDownloadProgress: (progressEvent) => {
        setUserDownloadProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
    })
      .then((res) => {
        const userList = [];
        for (let i = 0; i < res.data.length; i += 1) {
          userList.push({ value: res.data[i].id, label: res.data[i].email });
        }
        setAllUser(userList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data, initialize, token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (percent) => {
        setProgress(Math.round((100 * percent.loaded) / percent.total));
      },
    };

    const articleFormData = new FormData();
    const userData = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL));
    articleFormData.append('author_id', e.target.author_id.value);
    articleFormData.append('editor_id', userData.id);
    articleFormData.append('title', e.target.title.value);
    articleFormData.append('subcategory_2_id', e.target.subcategory_2_id.value);
    if (image) {
      articleFormData.append('image', image[0]);
    }
    if (e.target.raw_file.files[0]) {
      articleFormData.append('raw_file', e.target.raw_file.files[0]);
    }
    articleFormData.append('content', content);
    articleFormData.append('is_publish', e.target.is_publish.checked);

    axios
      .patch(`${POST_URL}/${params.id}/`, articleFormData, options)
      .then(() => {
        history.push('/admin/articles');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (

    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Detail Artikel</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Judul Artikel</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Kategori</span>
              <div className="form__form-group-field">
                <Field
                  name="subcategory_2_id"
                  component={renderSelectField}
                  type="text"
                  options={articleCategory}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Author</span>
              {
                userDownloadProgress >= 100
                  ? (
                    <div className="form__form-group-field">
                      <Field
                        name="author_id"
                        component={renderSelectField}
                        type="text"
                        options={allUser}
                        isDisabled={!isEditable}
                      />
                    </div>
                  )
                  : (
                    <div className="progress-wrap progress-wrap--middle">
                      <Progress animated value={userDownloadProgress} />
                    </div>
                  )
              }
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Editor</span>
              <div className="form__form-group-field">
                <Field
                  name="editor_id"
                  component="input"
                  type="text"
                  disabled
                />
              </div>
            </div>
            <div className="form__half mr-30 lg-mr-0">
              <div className="form__form-group">
                <span className="form__form-group-label block">Unggah Gambar</span>
                <span className="form__form-group-label">Gambar Awal{data.image ? '' : ': Tidak ada Gambar'}</span>
                {data.image
                && <img className="initial-image" src={data.image} alt="" />}
              </div>
            </div>
            {
              isEditable
              && (
              <div className="form__half">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      onChange={(e) => setImage(e)}
                      name="imageUpload"
                      component={renderDropZoneField}
                    />
                  </div>
                </div>
              </div>
              )
            }
            <div className="form__form-group">
              <span className="form__form-group-label block">File Mentah</span>
              <span className="form__form-group-label">File Mentah Awal{data.raw_file ? <a href={data.raw_file}>: link file</a> : ': Tidak ada file'}</span>
              {
                isEditable
                && (
                <div className="form__form-group-field">
                  <Field
                    name="raw_file"
                    component={renderFileInputField}
                  />
                </div>
                )
              }
              {/* {alertFilePDF && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file pdf</span>
                )} */}
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Isi Artikel</span>
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  disabled={!isEditable}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                  apiKey="hyf8znnbn86fszd4e8yki04zw6k2rc1ilfg4xrihopdppidh"
                  initialValue={data.content}
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="is_publish"
                  component={renderCheckBoxField}
                  label="Apakah artikel ini ingin ditampilkan ?"
                  disabled={!isEditable}
                />
              </div>
            </div>
            {progress !== null && (
              <div className="form__form-group">
                <div className="progress-wrap progress-wrap--middle">
                  <Progress animated value={progress} />
                </div>
              </div>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button color={isEditable ? 'secondary' : 'warning'} type="button" onClick={() => setIsEditable(!isEditable)}>{isEditable ? 'Batal' : 'Ubah'}</Button>
              {
                isEditable
                  ? <Button color="primary" type="submit">Simpan Perubahan</Button>
                  : (
                    <Modal
                      color="danger"
                      title="Hapus Artikel"
                      header
                      btn="Hapus"
                      deleteFunc={() => {
                        const options = {
                          headers: {
                            'Content-Type':
                              'application/json',
                            Authorization: `Token ${token}`,
                          },
                        };
                        axios
                          .delete(
                            `${ARTICLE_URL}/${data.id}`,
                            options,
                          )
                          .then(() => {
                            history.push('/admin/articles');
                          })
                          .catch(() => {
                            console.log('error');
                          });
                      }}
                      message="Apakah anda yakin ingin menghapus artikel ini ?"
                    />
                  )
              }
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_article', // a unique identifier for this form
})(ArticleEditForm);
