import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar, Card, CardBody, Col, Spinner,
} from 'reactstrap';
import XLSX from 'xlsx';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';

const KelasListTable = ({ listDataTable, isLoading }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const exportToExcel = () => {
    const exportData = listDataTable.tableRowsData.map((rowData) => ({
      name: rowData.name,
      address: rowData.address,
      email: rowData.email,
      phone: rowData.phone,
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DataMembers');
    XLSX.writeFile(wb, 'DataMembers.xlsx');
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Daftar Anggota IPI</h5>
            {!isLoading && (
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <button
                  type="button"
                  className="btn btn-primary products-list__btn-add"
                  onClick={exportToExcel}
                >
                  Export Data
                </button>
              </ButtonToolbar>
            )}
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

KelasListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool,
};

KelasListTable.defaultProps = {
  isLoading: false,
};

export default KelasListTable;
