/* eslint-disable no-console */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';
import { COMPETITION_URL } from '../../../../../../utils/endpoint';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/types';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const ExportCompetitionMember = () => {
  const params = useParams();
  const [userManagement, setuserManagement] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${COMPETITION_URL}${params.id}`, options)
      .then((res) => {
        setuserManagement(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);
  const data = userManagement !== null && userManagement.competition_registration_detail
    ? userManagement.competition_registration_detail.map((e) => ({
      date: e.created_at,
      name: e.user_detail.fullname,
      address: e.user_detail.address,
      email: e.user_detail.email,
      phone: e.user_detail.phone,
      instagram: e.instagram_account,
    })) : [];
  //   console.log('WKWK', data, userManagement !== null && userManagement.title);
  return (
    <ExcelFile filename={`Registrasi-${userManagement.name}`} element={<Button>Download</Button>}>
      <ExcelSheet data={data} name="Event">
        <ExcelColumn label="date" value="date" />
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Address" value="address" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Phone" value="phone" />
        <ExcelColumn label="Instagram" value="instagram" />
      </ExcelSheet>
    </ExcelFile>
  );
};
export default ExportCompetitionMember;
