import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row, Col,
} from 'reactstrap';
import Table from './components/GrupListTable';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/types';
import { GRUP_URL } from '../../../../utils/endpoint';
import CreateDataGrup from './components/CreateDataGrup';

const ListingGrup = () => {
  const [grup, setGrup] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const listDataTable = CreateDataGrup(grup);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(GRUP_URL, options)
      .then((res) => {
        setGrup(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Grup Blast</h3>
        </Col>
      </Row>
      <Row>
        <Table listDataTable={listDataTable} isLoading={isLoading} />
      </Row>
    </Container>
  );
};

export default ListingGrup;
