/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Progress,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router';
import renderSelectField from '../../../../../shared/components/form/Select';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import {
  BLASTEMAIL_URL,
  COMPETITION_URL,
  EVENT_URL,
} from '../../../../../utils/endpoint';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import renderFileInputField from '../../../../../shared/components/form/FileInput';

const ArticleForm = () => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  const editorRef = useRef(null);
  const [content, setContents] = useState('');
  const [userDownloadProgress, setUserDownloadProgress] = useState(0);
  const [allUser, setAllUser] = useState([]);
  const [listEvent, setListEvent] = useState([]);
  const [progress, setProgress] = useState(null);
  const [isCompetition, setCompetition] = useState(false);
  const [isEvent, setEvent] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(COMPETITION_URL, {
        ...options,
        onDownloadProgress: (progressEvent) => {
          setUserDownloadProgress(
            Math.round((100 * progressEvent.loaded) / progressEvent.total),
          );
        },
      })
      .then((res) => {
        const userList = [];
        for (let i = 0; i < res.data.length; i += 1) {
          userList.push({ value: res.data[i].id, label: res.data[i].name });
        }
        setAllUser(userList);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(EVENT_URL, {
        ...options,
        onDownloadProgress: (progressEvent) => {
          setUserDownloadProgress(
            Math.round((100 * progressEvent.loaded) / progressEvent.total),
          );
        },
      })
      .then((res) => {
        const userList = [];
        for (let i = 0; i < res.data.length; i += 1) {
          userList.push({ value: res.data[i].id, label: res.data[i].title });
        }
        setListEvent(userList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('e', e);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    const data = new FormData();
    // data.append('data_type', e.target.email.value);
    if (e.target.data_type) {
      data.append('data_type', e.target.data_type.value);
      if (e.target.email.value === 'data event') {
        data.append('id_event', e.target.event_id.value);
      }
    }
    if (e.target.excel.files[0]) {
      data.append('file_excel', e.target.excel.files[0]);
    }
    data.append('subject', e.target.subject.value);
    data.append('content', content);

    axios
      .post(`${BLASTEMAIL_URL}`, data, options)
      .then((res) => {
        console.log(res);
        history.push('/admin/image');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Broadcast Email</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Excel Penerima</span>
              <div className="form__form-group-field">
                <input type="file" name="excel" id="" />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Subjek Email</span>
              <div className="form__form-group-field">
                <Field
                  name="subject"
                  component="input"
                  type="text"
                  placeholder="Masukan subjek artikel"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Broadcasting</span>
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                  apiKey="hyf8znnbn86fszd4e8yki04zw6k2rc1ilfg4xrihopdppidh"
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                    mobile: {
                      skin: 'snow',
                      icons: 'thin',
                      // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                      height: 500,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen textcolor ',
                        'insertdatetime media table paste code help wordcount',
                        'image code',
                      ],
                      image_title: true,
                      automatic_uploads: true,
                      file_picker_types: 'image',
                      textcolor_rows: '4',
                      file_picker_callback(callback, value, meta) {
                        if (meta.filetype === 'image') {
                          const input = document.getElementById('my-file');
                          input.click();
                          input.onchange = function () {
                            const file = input.files[0];
                            const reader = new FileReader();
                            reader.onload = function (e) {
                              callback(e.target.result, {
                                alt: file.name,
                              });
                            };
                            reader.readAsDataURL(file);
                          };
                        }
                      },

                      content_style:
                        "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                      font_formats: 'Nunito',

                      toolbar:
                        'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                    },
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">Penerima Email</span>
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component={renderSelectField}
                  type="text"
                  options={[
                    { value: 'data user', label: 'data user' },
                    // { value: 'data newsletter', label: 'data newsletter' },
                    // { value: 'data lomba', label: 'data lomba' },
                    { value: 'data event', label: 'data event' },
                    { value: 'green.pramuja@gmail.com', label: 'green.pramuja@gmail.com' },
                    { value: 'widhi.igen2023@gmail.com', label: 'widhi.igen2023@gmail.com' },
                    { value: 'khoirula964@gmail.com', label: 'khoirula964@gmail.com' },
                  ]}
                  onChange={(e) => {
                    // e.preventDefault();
                    if (e.value === 'data lomba') {
                      setCompetition(true);
                      setEvent(false);
                    } else if (e.value === 'data event') {
                      setEvent(true);
                      setCompetition(false);
                    } else {
                      setCompetition(false);
                      setEvent(false);
                    }
                  }}
                  placeholder="Masukan Penerima artikel"
                />
              </div>
            </div>
            {
              isCompetition
            && (
            <div className="form__form-group">
              <span className="form__form-group-label">Lomba</span>
              {
                userDownloadProgress >= 100
                  ? (
                    <div className="form__form-group-field">
                      <Field
                        name="event_id"
                        component={renderSelectField}
                        type="text"
                        options={allUser}
                        placeholder="Masukan jenis lomba"
                      />
                    </div>
                  )
                  : (
                    <div className="progress-wrap progress-wrap--middle">
                      <Progress animated value={userDownloadProgress} />
                    </div>
                  )
              }
            </div>
            )
            }
            {
              isEvent
            && (
            <div className="form__form-group">
              <span className="form__form-group-label">Kegiatan Seminar</span>
              {
                userDownloadProgress >= 100
                  ? (
                    <div className="form__form-group-field">
                      <Field
                        name="event_id"
                        component={renderSelectField}
                        type="text"
                        options={listEvent}
                        placeholder="Masukan jenis kegiatan"
                      />
                    </div>
                  )
                  : (
                    <div className="progress-wrap progress-wrap--middle">
                      <Progress animated value={userDownloadProgress} />
                    </div>
                  )
              }
            </div>
            )
            } */}

            {progress !== null && (
              <div className="form__form-group">
                <div className="progress-wrap progress-wrap--middle block">
                  <Progress animated value={progress} />
                </div>
              </div>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">
                Save
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_broadcasting', // a unique identifier for this form
})(ArticleForm);
