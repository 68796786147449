/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
 Container, Col, Row, Card, CardBody,
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import classnames from 'classnames';
import axios from 'axios';
import Member from './components/CompetitionMemberListTable';
import Register from './components/CompetitionRegisteredListTable';
import CreateDataCompetitionMember from './components/CreateDataCompetitionMember';
import CreateDataCompetitionRegister from './components/CreateDataCompetitionRegistered';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { COMPETITION_URL } from '../../../../../utils/endpoint';

const CompetitionMembers = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const history = useHistory();
  const params = useParams();
  const [competition, setCompetition] = useState([]);

  const { location } = history;
  const { state } = location;
  const { data } = state;

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${COMPETITION_URL}${params.id}`, options)
      .then((res) => {
        console.log(res);
        setCompetition(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const listDataTableMember = CreateDataCompetitionMember(competition.competition_member_detail);
  const listDataTableRegister = CreateDataCompetitionRegister(data.competition_registration_detail);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Peserta Kompetisi</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--justify">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggle('1')}
                      >
                        Registrasi
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggle('2')}
                      >
                        Peserta
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Register listDataTableRegister={listDataTableRegister} />
                    </TabPane>
                    <TabPane tabId="2">
                      <Member listDataTableMember={listDataTableMember} />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
  };
export default CompetitionMembers;
