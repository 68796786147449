/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
 Container, Col, Row,
} from 'reactstrap';
import Table from './components/Table';
import CreateTableData from './components/CreateData';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/types';
import { EVENT_URL } from '../../../../../utils/endpoint';

const Events = () => {
  const [userManagement, setuserManagement] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const listDataTable = CreateTableData(userManagement);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(EVENT_URL, options)
      .then((res) => {
        setuserManagement(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Kegiatan & Webinar</h3>
        </Col>
      </Row>
      <Row>
        <Table listDataTable={listDataTable} isLoading={isLoading} />
      </Row>
    </Container>
  );
  };
export default Events;
