import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TotalProfitEarned from './components/TotalProfitEarned';
import TotalCustomers from './components/TotalCustomers';
import TotalBookings from './components/TotalBookings';
import BookingCancels from './components/BookingCancels';
import PartnerTotal from './components/PartnerTotal';
import LibraryTotal from './components/LibraryTotal';

const Dashboard = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Dashboard</h3>
      </Col>
    </Row>
    <Row>
      <TotalProfitEarned />
      <TotalBookings />
      <TotalCustomers />
      <BookingCancels />
      <PartnerTotal />
      <LibraryTotal />
    </Row>
  </Container>
);

export default (Dashboard);
