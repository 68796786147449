/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

const PhotoFormatter = (value) => (
  <div className="products-list__img-wrap">
    <img src={value} alt="" />
  </div>
);

const articleDetail = (history, info) => {
  history.push({
    pathname: `/admin/article-detail/${info.post_id}/`,
    state: { data: info.post_detail },
  });
};

const CreateDataCompetitionMember = (dataAPI) => {
  // console.log('DATA USER', dataAPI);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Image',
        accessor: 'photo',
        disableGlobalFilter: true,
      },
      {
        Header: 'Judul',
        accessor: 'title',
      },
      {
        Header: 'Nama Pembuat',
        accessor: 'name',
      },
      {
        Header: 'Publish',
        accessor: 'publish',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        username: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            name: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            photo: PhotoFormatter(`${dataAPI[i].post_detail.image}`),
            title: dataAPI[i].post_detail.title,
            name: dataAPI[i].post_detail.author_detail?.fullname,
            publish: <div>{dataAPI[i].post_detail.is_publish === false ? '✘' : '✔'}</div>,
            status: [
              (<Button size="sm" color="success" onClick={() => articleDetail(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateDataCompetitionMember;
