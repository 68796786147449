export const BASE_URL = 'https://api.ibupembelajar.id';
export const API_URL = `${BASE_URL}/api-ipi`;

// AUTH
export const AUTH_URL_LOGIN = `${BASE_URL}/auth-ipi/token/login/`;
export const AUTH_URL_USERME = `${BASE_URL}/auth-ipi/users/me/`;
export const AUTH_URL_SIGNUP = `${BASE_URL}/auth-ipi/users/`;

// user
export const ALLUSER_URL = `${API_URL}/customuser/`;

// event
export const EVENT_URL = `${API_URL}/event/`;
export const EVENTUSER_URL = `${API_URL}/dashboard-eventregistration/`;

// article
export const ARTICLE_URL = `${API_URL}/all-post-article`;
export const POST_URL = `${API_URL}/all-post`;
export const ARTICLEUSER_URL = `${API_URL}/dashboard-post/`;

// article category
export const ARTICLECATEGORY2_URL = `${API_URL}/postsubcategory2/`;

// competition
export const COMPETITION_URL = `${API_URL}/postcompetition/`;

// courses
export const COURSES_URL = `${API_URL}/course/`;
export const COURSES_LEVEL_URL = `${API_URL}/courselevel/`;
export const COURSES_SCHEDULE_URL = `${API_URL}/courseschedule/`;

// library
export const LIBRARY_URL = `${API_URL}/library/`;
// library
export const ADVERTISEMENT_URL = `${API_URL}/advertisement/`;
// partner
export const PARTNER_URL = `${API_URL}/ourpartner/`;
// image blasting email
export const IMAGEGALLERY_URL = `${API_URL}/imagegallery/`;
export const BLASTEMAIL_URL = `${API_URL}/blastemail/`;

// Grup
export const GRUP_URL = `${API_URL}/groupcontact/`;
export const ASSIGN_GRUP_URL = `${API_URL}/assigngroupcontact/`;
export const GET_GRUP_URL = `${API_URL}/assignbyidgroupcontact/`;

// Blast WA
export const HISTORY_BLASTWA_URL = `${API_URL}/historybroadcast/`;
